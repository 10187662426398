import React from 'react'
import Grid from '@mui/material/Grid';

const Database = () => {
    return (
        <Grid item xs={8} >
            This is database page.
        </Grid>
    )
}

export default Database
