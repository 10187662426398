import React from 'react'

const Storage = () => {
    return (
        <div style={{marginLeft: '500px'}}>
            This is storage page.
        </div>
    )
}

export default Storage
